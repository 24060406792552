import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import {Button, CircularProgress, Backdrop, Modal} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert';
import MaterialTable from 'material-table'
import CPFMask from '../Outros/CPFMask';
import { LaConFetch } from 'links/LaConFetch';
import {link} from '../../links/Links';

function is_cpf(cpf) {	
	cpf = cpf.replace(/[^\d]+/g,'');	
	if(cpf == '') return false;	
	// Elimina CPFs invalidos conhecidos	
	if (cpf.length != 11 || 
		cpf == "00000000000" || 
		cpf == "11111111111" || 
		cpf == "22222222222" || 
		cpf == "33333333333" || 
		cpf == "44444444444" || 
		cpf == "55555555555" || 
		cpf == "66666666666" || 
		cpf == "77777777777" || 
		cpf == "88888888888" || 
		cpf == "99999999999")
			return false;		
    // Valida 1o digito	
    var add = 0;	
    var i;
    var rev;
    for (i=0; i < 9; i ++)		
      add += parseInt(cpf.charAt(i)) * (10 - i);	
      rev = 11 - (add % 11);	
      if (rev == 10 || rev == 11)		
        rev = 0;	
      if (rev != parseInt(cpf.charAt(9)))		
        return false;		
    // Valida 2o digito	
    add = 0;	
    for (i = 0; i < 10; i ++)		
      add += parseInt(cpf.charAt(i)) * (11 - i);	
    rev = 11 - (add % 11);	
    if (rev == 10 || rev == 11)	
      rev = 0;	
    if (rev != parseInt(cpf.charAt(10)))
      return false;		
    return true;   
}

const useStyles = makeStyles((theme) => ({
  
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '80%'
  },

}));
   
export default function Beneficios({familia_id}) {

  const classes = useStyles();

  // Pessoa
  const [pessoa_id, setPessoa_id] = useState("");
  const [nm_pessoa, setNm_pessoa] = useState("");
  
  // Beneficios
  const [id, setId] = useState("");
  const [cd_beneficio, setCd_beneficio] = useState("");
  const [nm_beneficio, setNm_beneficio] = useState("");
  const [ds_beneficio, setDs_beneficio] = useState("");
  const [nr_reg_nascimento, setNr_reg_nascimento] = useState("");
  const [nr_cpf_falecido, setNr_cpf_falecido] = useState("");
  const [ds_observacao, setDs_observacao] = useState("");

  const [nm_beneficio_outro, setNm_beneficio_outro] = useState("");
  const [outroBeneficioOff, setOutroBeneficioOff] = useState(true);

  /* Set Beneficio */
  const setBeneficio = (codigo) =>{

    setCd_beneficio(codigo);

    if (codigo === '1'){  
      setNm_beneficio_outro(null);
      setOutroBeneficioOff(true);
      setNm_beneficio('Auxílio Natalidade');
    }
    else if (codigo === '2'){
      setNm_beneficio_outro(null);
      setOutroBeneficioOff(true);
      setNm_beneficio('Auxílio Funeral');
    }    
    else if (codigo === '3'){
      setNm_beneficio_outro(null);
      setOutroBeneficioOff(true);
      setNm_beneficio('Item/Kit específico para enfrentamento de situações de Emergência ou Calamidade Pública');
    }    
    else if (codigo === '4'){
      setNm_beneficio_outro(null);
      setOutroBeneficioOff(true);
      setNm_beneficio('Cesta Básica');
    }    
    else if (codigo === '5'){
      setNm_beneficio_outro(null);
      setOutroBeneficioOff(true);
      setNm_beneficio('Aluguel social/pagamento de aluguel');
    }      
    else if (codigo === '6'){  
      setOutroBeneficioOff(false);
    }

  }
  
  const setBeneficioOutro = (texto) =>{
  
    setNm_beneficio_outro(texto);
    setNm_beneficio(texto);
  }

  const [sending, setSending] = useState(false);

   /* Cadastrar Beneficios */
  const enviar = () => {
    
    if(pessoa_id === "")
      swal("Pessoa é obrigatório!","", "error")

    else if(cd_beneficio === "")
      swal("Código do benefício é obrigatório!","", "error")

    else if(cd_beneficio === "2" && (nr_cpf_falecido === "" || nr_cpf_falecido === null))
      swal("CPF falecido é obrigatório!","", "error")

    else if(nr_cpf_falecido !== "" && nr_cpf_falecido !== null && !is_cpf(nr_cpf_falecido))
      swal("CPF inválido!","", "error")
      
    else if(cd_beneficio === "1" && (nr_reg_nascimento === "" || nr_reg_nascimento === null))
      swal("Número de registro de nascimento é obrigatório!","", "error")

    else{

      const formDados = {
        pessoa_id,
        cd_beneficio, 
        nm_beneficio,
        ds_beneficio,
        nr_reg_nascimento, 
        nr_cpf_falecido, 
        ds_observacao};

      setSending(true);

      // fetch(`${link}/api/cadastrarBeneficios`,{
      //     method:'POST',
      //     body:JSON.stringify(formDados),
      //     headers:{
      //         "Content-Type":'application/json',
      //         Accept:'application/json'
      //     }
      // })
      // .then(res => res.json())
      // .then(result => {
      //   load();
      //   setSending(false)
        
      // }).then(swal("Enviado com sucesso!","", "success"))
      // .then(setOpen(false));

    LaConFetch(`/api/cadastrarBeneficios`,
    result => {
      load();
      setSending(false);
      swal("Enviado com sucesso!","", "success")
      setOpen(false)
    },()=>{},formDados)
    
    }
  };

  /* Carregar Pessoa e Beneficios */
  const [rows, setRows] = useState([]);

  const load = () => {

    setSending(true);

    // fetch(`${link}/api/resultPessoa/${familia_id}`,{
    //   headers:{
    //       Accept:'application/json'
    //   }
    //   })
    //   .then(res => res.json())
    //   .then(result => {if(result.dados !== null)(setDadosPessoa(result.dados))})
    
    // fetch(`${link}/api/resultBeneficios/${familia_id}`,{
    //   headers:{
    //       Accept:'application/json'
    //   }
    //   })
    //   .then(res => res.json())
    //   .then(result => {if(result.dados !== null)(setRows(result.dados))})
    //   .then(result => setSending(false))

    LaConFetch(`/api/resultPessoa/${familia_id}`,
      result => {
        if(result.dados !== null){
          setDadosPessoa(result.dados)
        }
        setSending(false)
    },result => setSending(false))

    LaConFetch(`/api/resultBeneficios/${familia_id}`,
      result => {
        if(result.dados !== null){
          setRows(result.dados)
        }
        setSending(false)
    },result => setSending(false))
        
  };
  
  useEffect(() => {
    
    load();
    
  }, [])

  const [dadosPessoa, setDadosPessoa] = useState([{pessoa_id:"" , nm_pessoa:""}]);

  /* SetDadosBeneficios */
  const setDadosBeneficios = (dados) => {

    /* Dados */
    setId(dados.id);
    setBeneficio(dados.cd_beneficio);
    if(dados.cd_beneficio === '6'){
      setBeneficioOutro(dados.nm_beneficio);
    }
    setDs_beneficio(dados.ds_beneficio);
    setNr_reg_nascimento(dados.nr_reg_nascimento);
    setNr_cpf_falecido(dados.nr_cpf_falecido);
    setDs_observacao(dados.ds_observacao);
    setNm_pessoa(dados.nm_pessoa);
    setPessoa_id(dados.pessoa_id);
  };

   /* Limpa os dados */
   const clear = () => {

    /* Dados */
    setId("");
    setCd_beneficio("");
    setNm_beneficio("");
    setDs_beneficio("");
    setNr_reg_nascimento("");
    setNr_cpf_falecido("");
    setDs_observacao("");
    setNm_beneficio_outro("");
    setNm_pessoa("");
    setPessoa_id("");
  };

  const [open, setOpen] = React.useState(false);

  const [editing, setEditing] = React.useState(false);

  const handleOpen = () => {
    clear();
    setEditing(false)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const modalEdit = (dados) => {
    setDadosBeneficios(dados);
    setEditing(true)
    setOpen(true);
  };

  /* Editar Beneficios */
  const editar = (dados) => {
    
    if(cd_beneficio === "2" && (nr_cpf_falecido === "" || nr_cpf_falecido === null))
      swal("CPF do falecido é obrigatório!","", "error")

    else if(nr_cpf_falecido !== "" && nr_cpf_falecido !== null && !is_cpf(nr_cpf_falecido))
      swal("CPF inválido!","", "error")

    else if(cd_beneficio === "1" && (nr_reg_nascimento === "" || nr_reg_nascimento === null))
      swal("Número de registro de nascimento é obrigatório!","", "error")

    else{

      const formDados = {
        cd_beneficio, 
        nm_beneficio,
        ds_beneficio,
        nr_reg_nascimento, 
        nr_cpf_falecido, 
        ds_observacao};
          
      setSending(true);

      // fetch(`${link}/api/editarBeneficios/${id}`,{
      //     method:'POST',
      //     body:JSON.stringify(formDados),
      //     headers:{
      //         "Content-Type":'application/json',
      //         Accept:'application/json'
      //     }
      // })
      // .then(res => res.json())
      // .then(result => {
      //   load();
      //   setSending(false)
        
      // })
      // .then(swal("Editado com sucesso!","", "success"))
      // .then(setOpenEdit(false));

    LaConFetch(`/api/editarBeneficios/${id}`,
    result => {
      load();
      setSending(false);
      swal("Editado com sucesso!","", "success")
      setOpen(false)
    },()=>{},formDados)

    }

  };

  /* Remover Beneficios */
  const remover = (dados) => {

    const id = dados.id;

    setSending(true);

    // fetch(`${link}/api/removerBeneficios/${id}`,{
    //     method:'POST',               
    // })
    // .then(result => {
    //     setSending(false)
    // });

    LaConFetch(`/api/removerBeneficios/${id}`,
      result => {
        setSending(false)
    },result => setSending(false),{})

  };

  return (
    
    <div className={classes.root}>

      <Backdrop style={{zIndex:99}} open={sending}>
          <CircularProgress color="inherit" />
      </Backdrop>

      <Button onClick={handleOpen} style={{ float: 'right' }}  variant="contained" color="primary">
        Adicionar Benefício
      </Button>

      <br></br><br></br>
      <br></br><br></br>

      <div className="justify-content-center ml-5">

        <MaterialTable 

        columns={[
          { title: 'Nome', field: 'nm_pessoa', headerStyle: {fontWeight: 'bold'} },
          { title: 'Cód. Beneficio', field: 'cd_beneficio', headerStyle: {fontWeight: 'bold'} },
          { title: 'Nome Beneficio', field: 'nm_beneficio', headerStyle: {fontWeight: 'bold'} },
        ]}

        data={rows}

        actions={[
            {
              icon: 'edit',
              tooltip: 'Editar',
              onClick: (e,data) => modalEdit(data)
            },
            {
              icon: 'delete',
              tooltip: 'Remover',
              onClick: (e,data) => swal("Tem certeza que deseja remover o registro?", {
                buttons: {
                  remover: "Remover",
                  cancel: "Sair"
                },
              })
              .then((value) => {
                switch (value) {
                  case "remover":
                    remover(data);
                    swal("Removido com sucesso!","", "success");
                    load();
                    break;
                }
              })
            }
        ]}

        options={{
            actionsColumnIndex: -1
        }}
        
        localization={{
            body: {
            emptyDataSourceMessage: 'Nenhum registro para exibir'
            },
            toolbar: {
            searchTooltip: 'Pesquisar',
            searchPlaceholder: 'Pesquisar'
            },
            header: {
            actions: ''
            },
            pagination: {
            labelRowsSelect: 'linhas',
            labelDisplayedRows: '{to} linhas de {count}',
            firstTooltip: 'Primeira página',
            previousTooltip: 'Página anterior',
            nextTooltip: 'Próxima página',
            lastTooltip: 'Última página'
            }
        }}
        
        title="Benefícios Eventuais" />
              
      </div>

      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>

            <div className={classes.paper}>

              <InputLabel style={{ marginTop: 20, fontSize: 20  }}>Benefícios</InputLabel>

              {(editing === false) ? (
              <TextField
              variant="outlined"
              fullWidth
              style={{ marginTop: 15}} 
              id="pessoa_id" 
              value={pessoa_id}
              label="Nome da pessoa*"  
              onChange={(e) => setPessoa_id(e.target.value)}
              select>
                {dadosPessoa.map(item =>
                  <MenuItem value={item.id}>{item.nm_pessoa}</MenuItem>
                )}
              </TextField>
              ):(
              <TextField
              disabled={true}
              margin="normal"
              variant="outlined"
              style={{ marginTop: 15, width: '100%' }} 
              id="nm_pessoa" 
              value={nm_pessoa}
              label="Nome da pessoa"  
              />)}

              <TextField
                variant="outlined"
                style={{ marginTop: 15, marginRight: '2%', width: '58%' }} 
                id="cd_beneficio" 
                value={cd_beneficio}
                label="Código do benefício*" 
                onChange={(e) => setBeneficio(e.target.value)}
                select>
                <MenuItem value="1">1 - Auxílio Natalidade</MenuItem>
                <MenuItem value="2">2 - Auxílio Funeral</MenuItem>
                <MenuItem value="3">3 - Item/Kit específico para enfrentamento de situações de Emergência ou Calamidade Pública</MenuItem>
                <MenuItem value="4">4 - Cesta Básica</MenuItem>
                <MenuItem value="5">5 - Aluguel social/pagamento de aluguel</MenuItem>
                <MenuItem value="6">6 - Outro</MenuItem>       
              </TextField>

              {/* {cd_beneficio == '6'? */}
                <TextField   
                  margin="normal"
                  disabled={outroBeneficioOff}
                  style={{ width: '40%' }}     
                  id="nm_beneficio_outro"
                  value={nm_beneficio_outro}
                  label="Caso outro, qual?"
                  variant="outlined"
                  onChange={(e) => setBeneficioOutro(e.target.value)}
                />
              {/* :null} */}

              <TextField  
                margin="normal"
                style={{  width: '100%' }}      
                id="ds_beneficio"
                label="Descrição do benefício"
                variant="outlined"
                value={ds_beneficio} 
                onChange={(e) => setDs_beneficio(e.target.value)}
                multiline='true'
                rows='4'
              />

              <TextField  
                margin="normal"
                style={{  marginRight: '2%', width: '24%' }}      
                id="nr_reg_nascimento"
                label="Número de Registro de Nascimento"
                variant="outlined"
                value={nr_reg_nascimento} 
                onChange={(e) => setNr_reg_nascimento(e.target.value)}
              />

              <TextField   
                margin="normal"
                style={{ width: '24%' }}     
                id="nr_cpf_falecido"
                InputProps={{ inputComponent: CPFMask }}
                label="Número do CPF do falecido"
                variant="outlined"
                value={nr_cpf_falecido} 
                onChange={(e) => setNr_cpf_falecido(e.target.value)}
              />

              <TextField   
                margin="normal"
                style={{ width: '100%' }}     
                id="ds_observacao"
                label="Observação"
                variant="outlined"
                value={ds_observacao} 
                onChange={(e) => setDs_observacao(e.target.value)}
                multiline='true'
                rows='4'
              />

              <br></br><br></br>

              <Button onClick={handleClose} style={{ float: 'right', marginLeft:15, backgroundColor:"red" }} variant="contained" color="primary">
                Fechar
              </Button>

              <Button onClick={(editing===false)?enviar:editar} style={{ float: 'right' }}  variant="contained" color="primary">
                Enviar
              </Button>

              <br></br><br></br>

          </div>

        </Modal>
      </div>

    </div>
)}