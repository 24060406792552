import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {Button, CircularProgress, Backdrop, Modal} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert';
import MaterialTable from 'material-table'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LaConFetch } from 'links/LaConFetch';
import {link} from '../../links/Links';

const useStyles = makeStyles((theme) => ({
  
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '80%'
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

}));
   
export default function DescumprimentoEducacao({familia_id}) {

  const classes = useStyles();

  // Pessoa
  const [pessoa_id, setPessoa_id] = useState("");
  const [nm_pessoa, setNm_pessoa] = useState("");
  
  // Descumprimento
  const [id, setId] = useState("");
  const [dt_ocorrencia, setDt_ocorrencia] = useState("");
  const [cd_efeito, setCd_efeito] = useState("");
  const [nm_efeito, setNm_efeito] = useState("");
  const [st_suspensao, setSt_suspensao] = useState("");
  const [ds_observacao, setDs_observacao] = useState("");

  const [sending, setSending] = useState(false);

  useEffect(() => {
   
    if(cd_efeito === '1'){
        setNm_efeito("Advertência");
    } else if(cd_efeito === '2'){
        setNm_efeito("Bloqueio");
    } else if(cd_efeito === '3'){
        setNm_efeito("Suspensão");
    } else if(cd_efeito === '4'){
        setNm_efeito("Cancelamento");
    } else{
        setNm_efeito("");
    } 

  }, [cd_efeito])

  /* Cadastrar DescumprimentoEducacao*/
  const enviar = () => {

    if(pessoa_id === "")
      swal("Pessoa é obrigatório!","", "error")

    else if(dt_ocorrencia === "")
      swal("Data da ocorrência é obrigatório!","", "error")

    else if(cd_efeito === "")
      swal("Código do efeito é obrigatório!","", "error")

    else{

      const formDados = {
        pessoa_id,
        dt_ocorrencia, 
        cd_efeito,
        nm_efeito,
        st_suspensao, 
        ds_observacao};

      // setSending(true);

      // fetch(`${link}/api/cadastrarDescumprimentoEducacao`,{
      //     method:'POST',
      //     body:JSON.stringify(formDados),
      //     headers:{
      //         "Content-Type":'application/json',
      //         Accept:'application/json'
      //     }
      // })
      // .then(res => res.json())
      // .then(result => {
      //   load();
      //   setSending(false)
      // })
      // .then(swal("Enviado com sucesso!","", "success"))
      // .then(
      //   setOpen(false),
      //   );

      setSending(true);
    LaConFetch(`/api/cadastrarDescumprimentoEducacao`,
    result => {
      load();
      setSending(false);
      swal("Enviado com sucesso!","", "success")
      setOpen(false)
    },()=>{},formDados)

    }
  };

 /* Carregar Pessoa e Descumprimento*/
 const [rows, setRows] = useState([]);

 const load = () => {

  // setSending(true);

  // fetch(`${link}/api/resultPessoa/${familia_id}`,{
  //   headers:{
  //       Accept:'application/json'
  //   }
  //   })
  //   .then(res => res.json())
  //   .then(result => {if(result.dados !== null)(setDadosPessoa(result.dados))})
      
  // fetch(`${link}/api/resultDescumprimentoEducacao/${familia_id}`,{
  //   headers:{
  //       Accept:'application/json'
  //   }
  //   })
  //   .then(res => res.json())
  //   .then(result => {if(result.dados !== null)(setRows(result.dados))})
  //   .then(result => setSending(false))

  setSending(true);
  LaConFetch(`/api/resultPessoa/${familia_id}`,
    result => {
      if(result.dados !== null){
        setDadosPessoa(result.dados)
      }
      setSending(false)
  },result => setSending(false))

  LaConFetch(`/api/resultDescumprimentoEducacao/${familia_id}`,
    result => {
      if(result.dados !== null){
        setRows(result.dados)
      }
      setSending(false)
  },result => setSending(false))

 };
 
 useEffect(() => {
   
   load();
   
 }, [])

 const [dadosPessoa, setDadosPessoa] = useState([{pessoa_id:"" , nm_pessoa:""}]);

 /* SetDadosDescumprimento */
 const setDadosDescumprimento = (dados) => {

   /* Dados */
   setId(dados.id);
   setDt_ocorrencia(dados.dt_ocorrencia);
   setCd_efeito(dados.cd_efeito)
   setNm_efeito(dados.nm_efeito)
   setSt_suspensao(dados.st_suspensao);
   setDs_observacao(dados.ds_observacao);
   setNm_pessoa(dados.nm_pessoa);
   setPessoa_id(dados.pessoa_id);
 };

  /* Limpa os dados */
  const clear = () => {

   /* Dados */
   setId("");
   setDt_ocorrencia("");
   setCd_efeito("")
   setNm_efeito("")
   setSt_suspensao("");
   setDs_observacao("");
   setNm_pessoa("");
   setPessoa_id("");
 };

 const [open, setOpen] = React.useState(false);

 const [editing, setEditing] = React.useState(false);

 const handleOpen = () => {
   clear();
   setOpen(true);
 };

 const handleClose = () => {
   setOpen(false);
 };

 const modalEdit = (dados) => {
   setDadosDescumprimento(dados);
   setEditing(true)
   setOpen(true);
 };

 /* Editar DescumprimentoEducacao*/
 const editar = () => {

  const formDados = {
    dt_ocorrencia, 
    cd_efeito,
    nm_efeito,
    st_suspensao, 
    ds_observacao};

  // setSending(true);

  // fetch(`${link}/api/editarDescumprimentoEducacao/${id}`,{
  //     method:'POST',
  //     body:JSON.stringify(formDados),
  //     headers:{
  //         "Content-Type":'application/json',
  //         Accept:'application/json'
  //     }
  // })
  // .then(res => res.json())
  // .then(result => {
  //   load();
  //   setSending(false)
  // })
  // .then(swal("Editado com sucesso!","", "success"))
  // .then(
  //   setOpenEdit(false),
  //   );

  setSending(true);
  LaConFetch(`/api/editarDescumprimentoEducacao/${id}`,
  result => {
    load();
    setSending(false);
    swal("Editado com sucesso!","", "success")
    setOpen(false)
  },()=>{},formDados)

};

 /* Remover Descumprimento */
 const remover = (dados) => {

  const id = dados.id;

  setSending(true);
  LaConFetch(`/api/removerDescumprimentoEducacao/${familia_id}`,
    result => {
      setSending(false)
  },result => setSending(false),{})

 };

  return (
    
    <div className={classes.root}>

      <br></br><br></br>

      <Accordion>
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Descumprimento Educação</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ float: 'right', width:'100%' }}>

            <Button onClick={handleOpen} style={{ float: 'right' }}  variant="contained" color="primary">
              Adicionar Descumprimento
            </Button>

            <br></br><br></br>
            <br></br><br></br>

            <div className="justify-content-center ml-5">

              <MaterialTable 

              columns={[
                { title: 'Nome', field: 'nm_pessoa', headerStyle: {fontWeight: 'bold'} },
                { title: 'Data Ocorrência', field: 'dt_ocorrenciaTab', headerStyle: {fontWeight: 'bold'} },
                { title: 'Cód. Efeito', field: 'cd_efeito', headerStyle: {fontWeight: 'bold'} },
                { title: 'Nome Efeito', field: 'nm_efeito', headerStyle: {fontWeight: 'bold'} }
              ]}

              data={rows}

              actions={[
                  {
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: (e,data) => modalEdit(data)
                  },
                  {
                    icon: 'delete',
                    tooltip: 'Remover',
                    onClick: (e,data) => swal("Tem certeza que deseja remover o registro?", {
                      buttons: {
                        remover: "Remover",
                        cancel: "Sair"
                      },
                    })
                    .then((value) => {
                      switch (value) {
                        case "remover":
                          remover(data);
                          swal("Removido com sucesso!","", "success");
                          load();
                          break;
                      }
                    })
                  }
              ]}

              options={{
                  actionsColumnIndex: -1
              }}
              
              localization={{
                  body: {
                  emptyDataSourceMessage: 'Nenhum registro para exibir'
                  },
                  toolbar: {
                  searchTooltip: 'Pesquisar',
                  searchPlaceholder: 'Pesquisar'
                  },
                  header: {
                  actions: ''
                  },
                  pagination: {
                  labelRowsSelect: 'linhas',
                  labelDisplayedRows: '{to} linhas de {count}',
                  firstTooltip: 'Primeira página',
                  previousTooltip: 'Página anterior',
                  nextTooltip: 'Próxima página',
                  lastTooltip: 'Última página'
                  }
              }}
              
              title="Descumprimento Educação" />
                    
            </div>

          </Typography>
        </AccordionDetails>
      </Accordion>

      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>

            <div className={classes.paper}>

              <InputLabel style={{ marginTop: 20, fontSize: 20  }}>Descumprimento</InputLabel>

              {(editing === false) ? (
              <TextField
              variant="outlined"
              fullWidth
              style={{ marginTop: 15}} 
              id="pessoa_id" 
              value={pessoa_id}
              label="Nome da pessoa*"  
              onChange={(e) => setPessoa_id(e.target.value)}
              select>
                {dadosPessoa.map(item =>
                  <MenuItem value={item.id}>{item.nm_pessoa}</MenuItem>
                )}
              </TextField>
              ):(
              <TextField
              disabled={true}
              margin="normal"
              variant="outlined"
              style={{ marginTop: 15, width: '100%' }} 
              id="nm_pessoa" 
              value={nm_pessoa}
              label="Nome da pessoa"  
              />)}

              <TextField
                margin="normal"
                style={{ marginRight: '2%', width: '16%' }}  
                id="dt_ocorrencia"
                label="Data da ocorrência*"
                type="date"
                variant="outlined"
                value={dt_ocorrencia}
                onChange={(e) => setDt_ocorrencia(e.target.value)}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />    

              <TextField 
                variant="outlined"
                style={{ marginTop: 15, marginRight: '2%', width: '16%' }} 
                id="cd_efeito" 
                value={cd_efeito}
                label="Código do efeito*"  
                onChange={(e) => setCd_efeito(e.target.value)}
                select>
                <MenuItem value="1">1 - Advertência</MenuItem>
                <MenuItem value="2">2 - Bloqueio</MenuItem>
                <MenuItem value="3">3 - Suspensão</MenuItem>
                <MenuItem value="4">4 - Cancelamento</MenuItem>           
              </TextField>

              <FormControl style={{ marginTop: 15, width: '18%' }}  component="fieldset"> 
                <FormLabel component="legend">Solicitada suspensão do efeito?</FormLabel>
                <RadioGroup row value={st_suspensao} aria-label="st_suspensao" name="st_suspensao" onChange={(e) => setSt_suspensao(e.target.value)}>
                  <FormControlLabel value="Sim" control={<Radio color="primary"/>} label="SIM" />
                  <FormControlLabel value="Não" control={<Radio color="primary"/>} label="NÂO" />   
                </RadioGroup>
              </FormControl> 

              <TextField   
                margin="normal"
                style={{ width: '100%' }}     
                id="ds_observacao"
                label="Observação"
                variant="outlined"
                value={ds_observacao} 
                onChange={(e) => setDs_observacao(e.target.value)}
                multiline='true'
                rows='4'
              />

              <br></br><br></br>

              <Button onClick={handleClose} style={{ float: 'right', marginLeft:15, backgroundColor:"red" }} variant="contained" color="primary">
                Fechar
              </Button>

              <Button onClick={(editing===false)?enviar:editar} style={{ float: 'right' }}  variant="contained" color="primary">
                Enviar
              </Button>

              <br></br><br></br>

            </div>

        </Modal>
      </div>
                      
    </div>
)}