import { link } from './Links';
/**
* @param {string} api rest of link to api
* @param {function} success function to run when everything goes well 
* @param {function} error funciton to run on error
* @param {var} input in case its a POST
*/
export const LaConFetch = (api,success,error,input=null)=>{
    if(input){
        fetch(`${link}${api}`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    'Accept': 'application/json'
                },
                body: JSON.stringify(input),
            })
        .then(res=>res.json())
        .then((result)=>{
            if(success){
                success(result);
            }
        },(err)=>{
            if(error){
                error(err)
            }
            console.error(err)
        });
    }else{
        fetch(`${link}${api}`,{
            headers:{
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'Accept': 'application/json'
            }
        })
        .then(res=>res.json())
        .then((result)=>{
            if(success){
                success(result);
            }
        },(err)=>{
            if(err){
                error(err);
            }
            console.error(err)
        });
    }
}

export const LaConFileUpload=(api,files=[],otherdata=[],success=()=>{},onError=()=>{})=>{
    const req = new XMLHttpRequest();
    let form = new FormData()

    files.forEach(file=>{
        form.append(file.name,file.file)
    })

    otherdata.forEach(data=>{
        form.append(data.name,data.value)
    })


    req.open('POST', `${link}${api}`);
    req.setRequestHeader('Authorization', `Bearer ${sessionStorage.getItem('token')}`)
    req.setRequestHeader('Accept', 'application/json')
    req.send(form);

    req.onreadystatechange = () => {
        if (req.readyState === 4 && req.status === 200) {
            try{
                let response = JSON.parse(req.response)
                success(response)
            }catch(err){
                onError(req)
            }
        }else if(req.readyState === 4 && req.status !== 200){
            onError(req)
        }
    }
}