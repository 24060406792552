import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {Button, CircularProgress, Backdrop, Modal, CardHeader} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert';
import MaterialTable from 'material-table'
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import moment from 'moment';
import { LaConFetch } from 'links/LaConFetch';
import {link} from '../../links/Links';
import EditAgenda from 'views/Agenda/EditAgenda';
import NovaAgenda from 'views/Agenda/NovaAgenda';
import { _MaterialTableLocalization } from 'links/TableConstant';
import { ExitToAppRounded } from '@material-ui/icons';
import CrudEdit from 'components/Crud/CrudEdit';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="R$ "
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '80%'
  },

}));
   
export default function Encaminhamento({familia_id}) {

  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  const [addSlot,setAddSlot] = useState(false)
  const [showSlot,setShowSlot] = useState(false)

  // Pessoa
  const [pessoa_id, setPessoa_id] = useState("");
  const [nm_pessoa, setNm_pessoa] = useState("");
  const [sending, setSending] = useState(false);

  const [encaminhados, setEncaminha] = useState([]);

  const [tipos,setTipos] = useState([])
  const [destinos,setDestinos] = useState([])

  const [toAdd,setToAdd] = useState(false)
  const [toShow,setToShow] = useState(false)
  const [atendimentoModal,setAtendimentoModal] = useState(false)

  const [edit,setEdit] = useState(false)
  const [registro,setRegistro] = useState({
    id:'',
    pessoa_id:'',
    destino_id:'',
    assunto:'',
    feito_em:'',
    tipo_id:'',
    descricao:'',
  });
  const onCloseModal=(reload=false)=>{
    setToShow(false)
    setToAdd(false)
    if(reload){
      loadEncaminhamentos()
    }
  }

  /* Cadastrar CondTrabalho*/
  const enviar = () => {
    let url = (registro.id) ? `edit/${registro.id}` : `new`
    LaConFetch(`/api/encaminhamento/${url}`,
    result => {
      setSending(false);
      swal("Enviado com sucesso!","", "success")
      loadEncaminhamentos()
      handleClose()
    },{
    },registro)
  };

  const loadEncaminhamentos = () => {
    setSending(true)
    LaConFetch(`/api/encaminhamento/recebido`,
      result => {
        if(result.items !== null){
          setEncaminha(result.items)
        }
        setSending(false)
    },result => setSending(false))
  }

  const loadAux = () => {
    setSending(true)
    LaConFetch(`/api/encaminhamento/tipo`,
      result => {
        if(result.items !== null){
          setTipos(result.items)
        }
        setSending(false)
    },result => setSending(false))
    LaConFetch(`/api/creas`,
      result => {
        if(result.items !== null){
          setDestinos(result.items)
        }
        setSending(false)
    },result => setSending(false))
  }

    
  useEffect(() => {
    loadEncaminhamentos()
    loadAux()
  }, [])

  

  const handleRegistro=(field,value)=>{
    let hold = {...registro}
    hold[field] = value
    setRegistro(hold)
  }

  const [dadosPessoa, setDadosPessoa] = useState([{pessoa_id:"" , nm_pessoa:""}]);

  const [open, setOpen] = React.useState(false);

  const [openEdit, setOpenEdit] = React.useState(false);

  const handleOpen = () => {
    setRegistro({
      id:'',
      pessoa_id:'',
      destino_id:'',
      assunto:'',
      feito_em:'',
      tipo_id:'',
      descricao:'',

    })
    setOpen(true);
  };

  const handleClose = () => {
    setEdit(false)
    setOpen(false);
  };

  const regAtendimento = () => {
    setAtendimentoModal(true)
  }

  const regVisita = () => {
    if(registro){
      if(registro?.agenda_id){
        setToShow(true)
      }else{
        setToAdd(true)
      }
    }
  }

  const geraGuia = () => {
      window.open(`${link}/relatorio/guia_encaminhamento/${registro.id}`)
  }

  return (
    
    <div className={classes.root}>

      <Backdrop style={{zIndex:99}} open={sending}>
          <CircularProgress color="inherit" />
      </Backdrop>

      <div className="justify-content-center ml-5">

        <div class="bg-primary" style={{backgroundColor:'rgb(37, 85, 39)',padding:'10px'}}>
          <div class="clearfix">
          <h4 style={{float:'left',margin:'3px 0'}}>Caixa de Entrada</h4>
          <Button onClick={handleOpen} style={{ float: 'right' }}  variant="contained" color="primary">
            Novo Encaminhamento
          </Button>
          </div>
        </div>
        <MaterialTable 

        columns={[
        { title: 'Protocolo', field: 'protocolo', headerStyle: {fontWeight: 'bold'} },
        { title: 'Unidade de Origem', field: 'funcionario.local.nome', headerStyle: {fontWeight: 'bold'} },
        { title: 'Enc. pelo Técnico', field: 'funcionario.name', headerStyle: {fontWeight: 'bold'} },
        { title: 'Assunto', field: 'assunto', headerStyle: {fontWeight: 'bold'} },
        { title: 'Beneficiário', field: 'pessoa.nm_pessoa', headerStyle: {fontWeight: 'bold'} },
        { title: 'Data da Ação', field: 'feito_em', render: rowData => moment(rowData.feito_em,'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm'), headerStyle: {fontWeight: 'bold'} },
        { title: 'Situação', field: 'status', headerStyle: {fontWeight: 'bold'} },
        ]}

        data={encaminhados}

        actions={[
            {
            icon: 'edit',
            tooltip: 'Editar',
            onClick: (e,data) => {
              setRegistro(data)
              setEdit(true)
              setOpen(true)
            }
            },
        ]}

        options={{
            actionsColumnIndex: -1,
            rowStyle: rowData => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
            })
        }}

        localization={_MaterialTableLocalization}

        title="Lista de Encaminhamentos" />
      </div>

      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>

            <div className={classes.paper}>

              <InputLabel style={{ marginTop: 20, fontSize: 20  }}>Encaminhamento</InputLabel>        

              <Grid container spacing={3}>
                <Grid lg={12}>
                    {edit?<TextField
                      fullWidth variant="outlined" margin="normal"
                      disabled={true} value={registro?.pessoa?.nm_pessoa ?? ''}
                    />:<TextField
                        variant="outlined" fullWidth margin="normal"
                        value={registro.pessoa_id} label="Beneficiário*"
                        onChange={(e) => handleRegistro('pessoa_id',e.target.value)} select
                      >
                          {dadosPessoa.map(item =>
                            <MenuItem value={item.id}>{item.nm_pessoa}</MenuItem>
                          )}
                    </TextField>}
                </Grid>
                <Grid lg={6}>
                    <TextField
                        variant="outlined" fullWidth margin='normal'
                        value={registro.destino_id}
                        label="Destino*"  
                        onChange={(e) => handleRegistro('destino_id',e.target.value)}
                        select>
                        {destinos.map(item =>
                        <MenuItem value={item.id}>{item.nome}</MenuItem>
                        )}
                    </TextField>
                </Grid>
                <Grid lg={6}>
                  <TextField   
                    margin="normal" fullWidth label="Assunto*" variant="outlined"
                    value={registro.assunto} onChange={(e) => handleRegistro('assunto',e.target.value)}
                  />
                </Grid>
                <Grid lg={6}>
                    <TextField
                    variant="outlined" margin="normal" fullWidth 
                    label="Data da Ação*" type="date"
                    value={registro.feito_em}
                    onChange={(e) => handleRegistro('feito_em',e.target.value)}
                    InputLabelProps={{ shrink: true, }}
                />
                </Grid>
                <Grid lg={6}>
                  <TextField
                        variant="outlined" fullWidth margin='normal'
                        value={registro.status} label="Situação*"  
                        onChange={(e) => handleRegistro('status',e.target.value)} select
                      >
                        <MenuItem value="Aguardando Captura">Aguardando Captura</MenuItem>
                        <MenuItem value="Capturados">Capturados</MenuItem>
                    </TextField>
                </Grid>
                <Grid lg={12}>
                    <TextField
                        variant="outlined" fullWidth margin="normal"
                        value={registro.tipo_id}
                        label="Tipo de Encaminhamento*"  
                        onChange={(e) => handleRegistro('tipo_id',e.target.value)}
                        select>
                        {tipos.map(item =>
                        <MenuItem value={item.id}>{item.nome}</MenuItem>
                        )}
                    </TextField>
                </Grid>
                <Grid lg={12}>
                    <TextField   
                        margin="normal" fullWidth variant="outlined"
                        label="Descrição da Ação*" value={registro.descricao} 
                        onChange={(e) => handleRegistro('descricao',e.target.value)}
                        multiline rows={4}
                    />
                </Grid>

              </Grid>
            
              <br></br><br></br>

              <Button onClick={handleClose} style={{ float: 'right', marginLeft:15, backgroundColor:"red" }} variant="contained" color="primary">
                Fechar
              </Button>

              <Button onClick={enviar} style={{ float: 'right' }}  variant="contained" color="primary">
                Enviar
              </Button>

              {edit ? <>
                <Button onClick={()=>geraGuia()} style={{ float: 'left', marginLeft:15, backgroundColor:"#ddd", color: "#000"  }}  variant="contained" color="primary">
                  Gerar Guia
                </Button>
                
                <Button onClick={regVisita} style={{ float: 'left', marginLeft:15, backgroundColor:"orange"  }}  variant="contained" color="primary">
                  {registro?.agenda_id ? 'Editar Visita':'Registrar Visita'}
                </Button>
                
                <Button onClick={regAtendimento} style={{ float: 'left', marginLeft:15, backgroundColor:"blue"  }}  variant="contained" color="primary">
                  Registrar Atendimento
                </Button>
              </>:null}

              <br></br><br></br>

            </div>

        </Modal>
      </div>

      <Modal open={toAdd} onClose={()=>onCloseModal(false)}>
          <div className='container' style={{marginTop:50}}>
              {toAdd && registro? <NovaAgenda slot={{start:moment().toDate(),end:moment().add(10,'minutes').toDate()}} onClose={onCloseModal} url={`/api/encaminhamento/agenda/${registro?.id}/new`} pessoa={registro?.pessoa}/>:null}
          </div>
      </Modal>
      <Modal open={toShow} onClose={()=>onCloseModal(false)}>
          <div className='container' style={{marginTop:50}}>
              {toShow && registro? <EditAgenda slot={registro?.agenda} onClose={onCloseModal} url={`/api/encaminhamento/agenda/${registro?.id}`} pessoa={registro?.pessoa}/>:null}
          </div>
      </Modal>

      <Modal open={atendimentoModal} onClose={()=>setAtendimentoModal(false)}>
          <div className='container' style={{marginTop:50}}>
            <CrudEdit
              url={`/api/registro-atendimento`} title="Atendimento" onClose={()=>setAtendimentoModal(false)} add
              fields={[
                {
                  field:'dt_atendimento',label:'Data do Atendimento',type:'date',grid:{xs:6}
                },{
                  field:'nm_tecnico',label:'Técnico',type:'text',grid:{xs:6}
                },{
                  field:'atendimento_id',label:'Atendimento',type:'select',grid:{xs:6},link:'/api/code/atendimento',nameKey:'nome',dataKey:'id'
                },{
                  field:'ds_atendimento',label:'Caso outro qual?',type:'text',grid:{xs:6}
                },{
                  field:'familia_id',value:registro?.pessoa?.familia_id,type:'default'
                }
              ]}
            />
          </div>
      </Modal>
    </div>
)}