import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {Button, CircularProgress, Backdrop, Modal, Grid} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert';
import MaterialTable from 'material-table'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LaConFetch } from 'links/LaConFetch';
import {link} from '../../links/Links';

const useStyles = makeStyles((theme) => ({
  
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '80%'
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  

}));
   
export default function Cuidados({familia_id}) {

  const classes = useStyles();

  // Pessoa
  const [pessoa_id, setPessoa_id] = useState("");
  const [nm_pessoa, setNm_pessoa] = useState("");
  
  // Gestação
  const [id, setId] = useState("");
  const [situacao, setSituacao] = useState("");
  const [descreva, setDescreva] = useState("");
  const [dt_anotacao, setDt_anotacao] = useState("");
  const [ds_observacao, setDs_observacao] = useState("");

  const [sending, setSending] = useState(false);

  /* Cadastrar Gestação*/
  const enviar = () => {

    if(pessoa_id === "")
      swal("Pessoa é obrigatório!","", "error")

    else if(situacao === "")
    swal("Situação é obrigatória!","", "error")

    else if(descreva === "")
    swal("Descrição é obrigatório!","", "error")

    else if(dt_anotacao === "")
      swal("Data da anotação é obrigatório!","", "error")

    else{

      const formDados = {
        pessoa_id,
        situacao,
        descreva,
        dt_anotacao,
        ds_observacao};

      setSending(true);

      // fetch(`${link}/api/cadastrarGestacao`,{
      //     method:'POST',
      //     body:JSON.stringify(formDados),
      //     headers:{
      //         "Content-Type":'application/json',
      //         Accept:'application/json'
      //     }
      // })
      // .then(res => res.json())
      // .then(result => {
      //   load();
      //   setSending(false)
      // })
      // .then(swal("Enviado com sucesso!","", "success"))
      // .then(
      //   setOpen(false),
      //   );

      LaConFetch(`/api/cadastrarCuidado`,
      result => {
        load();
        setSending(false);
        swal("Enviado com sucesso!","", "success")
        setOpen(false)
      },()=>{},formDados)

    }
  };

  
   /* Carregar Pessoa e Gestação*/
   const [rows, setRows] = useState([]);

   const load = () => {

    setSending(true);
 
    // fetch(`${link}/api/resultPessoa/${familia_id}`,{
    //   headers:{
    //       Accept:'application/json'
    //   }
    //   })
    //   .then(res => res.json())
    //   .then(result => {if(result.dados !== null)(setDadosPessoa(result.dados))})
        
    // fetch(`${link}/api/resultGestacao/${familia_id}`,{
    //   headers:{
    //       Accept:'application/json'
    //   }
    //   })
    //   .then(res => res.json())
    //   .then(result => {if(result.dados !== null)(setRows(result.dados))})
    //   .then(result => setSending(false))

    LaConFetch(`/api/resultPessoa/${familia_id}`,
    result => {
      if(result.dados !== null){
        setDadosPessoa(result.dados)
      }
      setSending(false)
    },result => setDadosPessoa(false))  

    LaConFetch(`/api/resultCuidado/${familia_id}`,
    result => {
      if(result.dados !== null){
        setRows(result.dados)
      }
      setSending(false)
    },result => setSending(false))

   };
   
   useEffect(() => {
     
     load();
     
   }, [])
 
   const [dadosPessoa, setDadosPessoa] = useState([{pessoa_id:"" , nm_pessoa:""}]);
 
   /* SetDadosGestacao */
   const setDadosGestacao = (dados) => {

     /* Dados */
     setId(dados.id);
     setDt_anotacao(dados.dt_anotacao);
     setSituacao(dados.situacao_id)
     setDescreva(dados.descreva)
     setDs_observacao(dados.ds_observacao)
     setPessoa_id(dados.pessoa_id);
   };
 
    /* Limpa os dados */
    const clear = () => {
 
     /* Dados */
     setId("");
     setDt_anotacao("");
     setSituacao("");
     setDescreva("");
     setDs_observacao("");
     setPessoa_id("");
   };
 
   const [open, setOpen] = React.useState(false);
 
   //const [openEdit, setOpenEdit] = React.useState(false);
   const [editing, setEditing] = React.useState(false);
 
   const handleOpen = () => {
     clear();
     setEditing(false)
     setOpen(true);
   };
 
   const handleClose = () => {
     setOpen(false);
   };
 
  //  const handleOpenEdit = () => {
  //    setOpenEdit(true);
  //  };
 
  //  const handleCloseEdit = () => {
  //    setOpenEdit(false);
  //  };
 
   const modalEdit = (dados) => {
     setDadosGestacao(dados);
     setEditing(true)
     setOpen(true);
   };

   /* Editar Gestação*/
  const editar = () => {

    if(1==0)
      swal("Quantidade de meses é obrigatório!","", "error")

    else{

      const formDados = {
        pessoa_id,
        situacao,
        descreva,
        dt_anotacao,
        ds_observacao};

      setSending(true);

      // fetch(`${link}/api/editarGestacao/${id}`,{
      //     method:'POST',
      //     body:JSON.stringify(formDados),
      //     headers:{
      //         "Content-Type":'application/json',
      //         Accept:'application/json'
      //     }
      // })
      // .then(res => res.json())
      // .then(result => {
      //   load();
      //   setSending(false)
      // })
      // .then(swal("Editado com sucesso!","", "success"))
      // .then(
      //   setOpenEdit(false),
      //   );

      LaConFetch(`/api/editarCuidado/${id}`,
      result => {
        load();
        setSending(false);
        swal("Editado com sucesso!","", "success")
        setOpen(false)
      },()=>{},formDados)

    }
  };
 
   /* Remover Gestação */
   const remover = (dados) => {
 
    const id = dados.id;
 
     setSending(true);
 
    //  fetch(`${link}/api/removerGestacao/${id}`,{
    //      method:'POST',               
    //  })
    //  .then(result => {
    //      setSending(false)
    //  });

    LaConFetch(`/api/removerCuidado/${id}`,
      result => {
        setSending(false)
    },result => setSending(false),{})
 
   };

  return (
    
    <div className={classes.root}>

      <br></br><br></br>

      <Accordion>
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Cuidados</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ float: 'right', width:'100%' }}>

            <Button onClick={handleOpen} style={{ float: 'right' }}  variant="contained" color="primary">
            Adicionar Cuidados
            </Button>

            <br></br><br></br>
            <br></br><br></br>

            <div className="justify-content-center ml-5">

              <MaterialTable 

              columns={[
                { title: 'Nome', field: 'nm_pessoa', headerStyle: {fontWeight: 'bold'} },
                { title: 'Situação', field: 'situacao', headerStyle: {fontWeight: 'bold'} },
                { title: 'Data da Anotação', field: 'dt_anotacaoTab', headerStyle: {fontWeight: 'bold'} }
              ]}

              data={rows}

              actions={[
                  {
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: (e,data) => modalEdit(data)
                  },
                  {
                    icon: 'delete',
                    tooltip: 'Remover',
                    onClick: (e,data) => swal("Tem certeza que deseja remover o registro?", {
                      buttons: {
                        remover: "Remover",
                        cancel: "Sair"
                      },
                    })
                    .then((value) => {
                      switch (value) {
                        case "remover":
                          remover(data);
                          swal("Removido com sucesso!","", "success");
                          load();
                          break;
                      }
                    })
                  }
              ]}

              options={{
                  actionsColumnIndex: -1
              }}
              
              localization={{
                  body: {
                  emptyDataSourceMessage: 'Nenhum registro para exibir'
                  },
                  toolbar: {
                  searchTooltip: 'Pesquisar',
                  searchPlaceholder: 'Pesquisar'
                  },
                  header: {
                  actions: ''
                  },
                  pagination: {
                  labelRowsSelect: 'linhas',
                  labelDisplayedRows: '{to} linhas de {count}',
                  firstTooltip: 'Primeira página',
                  previousTooltip: 'Página anterior',
                  nextTooltip: 'Próxima página',
                  lastTooltip: 'Última página'
                  }
              }}
              
              title="Cuidados" />
                    
            </div>

          </Typography>
        </AccordionDetails>
      </Accordion>

      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>

            <div className={classes.paper}>

              <InputLabel style={{ marginTop: 20, fontSize: 20  }}>Cuidados</InputLabel>
              
              <Grid container>

              <Grid lg={12}>
              {(editing === false) ? (
              <TextField
              variant="outlined"
              fullWidth
              style={{ marginTop: 15}} 
              id="pessoa_id" 
              value={pessoa_id}
              label="Nome da pessoa*"  
              onChange={(e) => setPessoa_id(e.target.value)}
              select>
                {dadosPessoa.map(item =>
                  <MenuItem value={item.id}>{item.nm_pessoa}</MenuItem>
                )}
              </TextField>
              ):(
                <TextField
                disabled={true}
                variant="outlined"
                fullWidth
                style={{ marginTop: 15}} 
                id="pessoa_id" 
                value={pessoa_id}
                label="Nome da pessoa*"  
                onChange={(e) => setPessoa_id(e.target.value)}
                select>
                  {dadosPessoa.map(item =>
                    <MenuItem value={item.id}>{item.nm_pessoa}</MenuItem>
                  )}
                </TextField>)}
              </Grid>
              
              <Grid lg={12}>
              <TextField
              variant="outlined"
              fullWidth
              style={{ marginTop: 15}} 
              id="situacao" 
              value={situacao}
              label="Situação*"  
              onChange={(e) => setSituacao(e.target.value)}
              select>
                <MenuItem value="1">0 - Necessidade de Cuidados Especiais</MenuItem>
                <MenuItem value="2">1 - Insegurança/Insuficiência Alimentar</MenuItem>
                <MenuItem value="3">2 - Portador de Doença Grave</MenuItem>
                <MenuItem value="4">3 - Uso de Remédios Controlados</MenuItem>
                <MenuItem value="5">4 - Uso abusivo de álcool</MenuItem>
                <MenuItem value="6">5 - Uso de drogas</MenuItem>
              </TextField>
              </Grid>

              <Grid lg={12}>
              <TextField
                margin="normal"
                fullWidth
                style={{ marginRight: '2%' }}  
                id="descreva"
                label="Descreva*"
                variant="outlined"
                value={descreva} 
                onChange={(e) => setDescreva(e.target.value)}
              />
              </Grid>

              <TextField
                variant="outlined"
                margin="normal"
                style={{ marginRight: '2%', width: '16%' }}  
                id="dt_anotacao"
                label="Data da Anotação*"
                type="date"
                value={dt_anotacao}
                onChange={(e) => setDt_anotacao(e.target.value)}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField   
                margin="normal"
                style={{ width: '100%' }}     
                id="ds_observacao"
                label="Observação"
                variant="outlined"
                value={ds_observacao} 
                onChange={(e) => setDs_observacao(e.target.value)}
                multiline='true'
                rows='4'
              />
              
              </Grid>

              <br></br><br></br>

              <Button onClick={handleClose} style={{ float: 'right', marginLeft:15, backgroundColor:"red" }} variant="contained" color="primary">
                Fechar
              </Button>

              <Button onClick={(editing===false)?enviar:editar} style={{ float: 'right' }}  variant="contained" color="primary">
                Enviar
              </Button>

              <br></br><br></br>

            </div>

          </Modal>
        </div>

    </div>
)}