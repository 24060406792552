import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {Button, CircularProgress, Backdrop, Modal} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert';
import MaterialTable from 'material-table'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LaConFetch } from 'links/LaConFetch';
import {link} from '../../links/Links';

const useStyles = makeStyles((theme) => ({
  
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '80%'
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

}));
   
export default function Deficiencia({familia_id}) {

  const classes = useStyles();

  // Pessoa
  const [pessoa_id, setPessoa_id] = useState("");
  const [nm_pessoa, setNm_pessoa] = useState("");
  
  // Deficiencia
  const [id, setId] = useState("");
  const [cd_deficiencia, setCd_deficiencia] = useState("");
  const [nm_deficiencia, setNm_deficiencia] = useState("");
  const [st_cuidados, setSt_cuidados] = useState("");
  const [nm_responsavel, setNm_responsavel] = useState("");
  const [ds_observacao, setDs_observacao] = useState("");

  const [sending, setSending] = useState(false);

  useEffect(() => {

    if(cd_deficiencia === '1'){
      setNm_deficiencia("Cegueira");
    } else if(cd_deficiencia === '2'){
      setNm_deficiencia("Baixa Visão");
    } else if(cd_deficiencia === '3'){
      setNm_deficiencia("Surdez severa/profunda");
    } else if(cd_deficiencia === '4'){
      setNm_deficiencia("Surdez leve/moderada");
    } else if(cd_deficiencia === '5'){
      setNm_deficiencia("Deficiência física");
    } else if(cd_deficiencia === '6'){
      setNm_deficiencia("Deficiência mental ou intelectual");
    } else if(cd_deficiencia === '7'){
      setNm_deficiencia("Síndrome de Down");
    } else if(cd_deficiencia === '8'){
      setNm_deficiencia("Transtorno/doença mental");
    } else if(cd_deficiencia === '9'){
      setNm_deficiencia("Microcefalia");
    } else{
      setNm_deficiencia("");
    } 

  }, [cd_deficiencia])

  /* Cadastrar Deficiencia */
  const enviar = () => {

    if(pessoa_id === "")
      swal("Pessoa é obrigatório!","", "error")

    else if(cd_deficiencia === "")
      swal("Tipo de deficiência é obrigatório!","", "error")

    else{

      const formDados = {
        pessoa_id,
        cd_deficiencia, 
        nm_deficiencia,
        st_cuidados,
        nm_responsavel, 
        ds_observacao};

      // setSending(true);

      // fetch(`${link}/api/cadastrarDeficiencia`,{
      //     method:'POST',
      //     body:JSON.stringify(formDados),
      //     headers:{
      //         "Content-Type":'application/json',
      //         Accept:'application/json'
      //     }
      // })
      // .then(res => res.json())
      // .then(result => {
      //   load();
      //   setSending(false);
      // })
      // .then(swal("Enviado com sucesso!","", "success"))
      // .then(
      //   setOpen(false),
      //   );

    setSending(true);
    LaConFetch(`/api/cadastrarDeficiencia`,
    result => {
      load();
      setSending(false);
      swal("Enviado com sucesso!","", "success")
      setOpen(false)
    },()=>{},formDados)

    }
  };

  /* Carregar Pessoa e Deficiencia*/
  const [rows, setRows] = useState([]);

  const load = () => {

    setSending(true);

    // fetch(`${link}/api/resultPessoa/${familia_id}`,{
    //   headers:{
    //       Accept:'application/json'
    //   }
    //   })
    //   .then(res => res.json())
    //   .then(result => {if(result.dados !== null)(setDadosPessoa(result.dados))})
        
    // fetch(`${link}/api/resultDeficiencia/${familia_id}`,{
    //   headers:{
    //       Accept:'application/json'
    //   }
    //   })
    //   .then(res => res.json())
    //   .then(result => {if(result.dados !== null)(setRows(result.dados))})
    //   .then(result => setSending(false))

    LaConFetch(`/api/resultPessoa/${familia_id}`,
      result => {
        if(result.dados !== null){
          setDadosPessoa(result.dados)
        }
        setSending(false)
    },result => setSending(false))

    LaConFetch(`/api/resultDeficiencia/${familia_id}`,
      result => {
        if(result.dados !== null){
          setRows(result.dados)
        }
        setSending(false)
    },result => setSending(false))

  };
  
  useEffect(() => {
    
    load();
    
  }, [])

  const [dadosPessoa, setDadosPessoa] = useState([{pessoa_id:"" , nm_pessoa:""}]);

  /* SetDadosDeficiencia */
  const setDadosDeficiencia = (dados) => {

    /* Dados */
    setId(dados.id);
    setCd_deficiencia(dados.cd_deficiencia);
    setNm_deficiencia(dados.nm_deficiencia);
    setSt_cuidados(dados.st_cuidados);
    setNm_responsavel(dados.nm_responsavel);
    setDs_observacao(dados.ds_observacao);
    setNm_pessoa(dados.nm_pessoa);
    setPessoa_id(dados.pessoa_id);
  };

   /* Limpa os dados */
   const clear = () => {

    /* Dados */
    setId("");
    setCd_deficiencia("");
    setNm_deficiencia("");
    setSt_cuidados("");
    setNm_responsavel("");
    setDs_observacao("");
    setNm_pessoa("");
    setPessoa_id("");
  };

  const [open, setOpen] = React.useState(false);

  const [editing, setEditing] = React.useState(false);

  const handleOpen = () => {
    clear();
    setEditing(false)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const modalEdit = (dados) => {
    setDadosDeficiencia(dados);
    setEditing(true)
    setOpen(true);
  };

  /* Editar Deficiencia */
  const editar = () => {

    const formDados = {
      cd_deficiencia, 
      nm_deficiencia,
      st_cuidados,
      nm_responsavel, 
      ds_observacao};

    setSending(true);

    // fetch(`${link}/api/editarDeficiencia/${id}`,{
    //     method:'POST',
    //     body:JSON.stringify(formDados),
    //     headers:{
    //         "Content-Type":'application/json',
    //         Accept:'application/json'
    //     }
    // })
    // .then(res => res.json())
    // .then(result => {
    //   load();
    //   setSending(false);
    // })
    // .then(swal("Editado com sucesso!","", "success"))
    // .then(
    //   setOpenEdit(false),
    //   );

    LaConFetch(`/api/editarDeficiencia/${id}`,
    result => {
      load();
      setSending(false);
      swal("Editado com sucesso!","", "success")
      setOpen(false)
    },()=>{},formDados)

  };

  /* Remover Deficiencia */
  const remover = (dados) => {

    const id = dados.id;

    setSending(true);

    // fetch(`${link}/api/removerDeficiencia/${id}`,{
    //     method:'POST',               
    // })
    // .then(result => {
    //     setSending(false)
    // });

    LaConFetch(`/api/removerDeficiencia/${id}`,
      result => {
        setSending(false)
    },result => setSending(false),{})

  };


  return (
    
    <div className={classes.root}>

      <Backdrop style={{zIndex:99}} open={sending}>
          <CircularProgress color="inherit" />
      </Backdrop>

      <Accordion>
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Deficiência</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ float: 'right', width:'100%' }}>

            <Button onClick={handleOpen} style={{ float: 'right' }}  variant="contained" color="primary">
              Adicionar Deficiência
            </Button>

            <br></br><br></br>
            <br></br><br></br>

            <div className="justify-content-center ml-5">

              <MaterialTable 

              columns={[
                { title: 'Nome', field: 'nm_pessoa', headerStyle: {fontWeight: 'bold'} },
                { title: 'Cód. Deficiência', field: 'cd_deficiencia', headerStyle: {fontWeight: 'bold'} },
                { title: 'Nome Deficiência', field: 'nm_deficiencia', headerStyle: {fontWeight: 'bold'} },
              ]}

              data={rows}

              actions={[
                  {
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: (e,data) => modalEdit(data)
                  },
                  {
                    icon: 'delete',
                    tooltip: 'Remover',
                    onClick: (e,data) => swal("Tem certeza que deseja remover o registro?", {
                      buttons: {
                        remover: "Remover",
                        cancel: "Sair"
                      },
                    })
                    .then((value) => {
                      switch (value) {
                        case "remover":
                          remover(data);
                          swal("Removido com sucesso!","", "success");
                          load();
                          break;
                      }
                    })
                  }
              ]}

              options={{
                  actionsColumnIndex: -1
              }}
              
              localization={{
                  body: {
                  emptyDataSourceMessage: 'Nenhum registro para exibir'
                  },
                  toolbar: {
                  searchTooltip: 'Pesquisar',
                  searchPlaceholder: 'Pesquisar'
                  },
                  header: {
                  actions: ''
                  },
                  pagination: {
                  labelRowsSelect: 'linhas',
                  labelDisplayedRows: '{to} linhas de {count}',
                  firstTooltip: 'Primeira página',
                  previousTooltip: 'Página anterior',
                  nextTooltip: 'Próxima página',
                  lastTooltip: 'Última página'
                  }
              }}
              
              title="Deficiência" />
                    
            </div>

          </Typography>
        </AccordionDetails>
      </Accordion>

      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>

            <div className={classes.paper}>

              <InputLabel style={{ marginTop: 20, fontSize: 20  }}>Deficiência</InputLabel>

              {(editing === false) ? (
              <TextField
              variant="outlined"
              fullWidth
              style={{ marginTop: 15}} 
              id="pessoa_id" 
              value={pessoa_id}
              label="Nome da pessoa*"  
              onChange={(e) => setPessoa_id(e.target.value)}
              select>
                {dadosPessoa.map(item =>
                  <MenuItem value={item.id}>{item.nm_pessoa}</MenuItem>
                )}
              </TextField>
              ):(
              <TextField
              disabled={true}
              margin="normal"
              variant="outlined"
              style={{ marginTop: 15, width: '100%' }} 
              id="nm_pessoa" 
              value={nm_pessoa}
              label="Nome da pessoa"  
              />)}

              <TextField
                variant="outlined"
                style={{ marginTop: 15, marginRight: '2%', width: '30%' }} 
                id="cd_deficiencia" 
                value={cd_deficiencia}
                label="Qual o tipo de deficiência?*"  
                onChange={(e) => setCd_deficiencia(e.target.value)}
                select>
                <MenuItem value="1">1 - Cegueira</MenuItem>
                <MenuItem value="2">2 - Baixa Visão</MenuItem>
                <MenuItem value="3">3 - Surdez severa/profunda</MenuItem>
                <MenuItem value="4">4 - Surdez leve/moderada</MenuItem> 
                <MenuItem value="5">5 - Deficiência física</MenuItem> 
                <MenuItem value="6">6 - Deficiência mental ou intelectual</MenuItem> 
                <MenuItem value="7">7 - Síndrome de Down</MenuItem> 
                <MenuItem value="8">8 - Transtorno/doença mental</MenuItem>  
                <MenuItem value="9">9 - Microcefalia</MenuItem>            
              </TextField>

              <FormControl style={{ marginTop: 15, width: '30%' }}  component="fieldset">
                <FormLabel component="legend">Necessita de cuidador?</FormLabel>
                <RadioGroup row value={st_cuidados} aria-label="st_cuidados" name="st_cuidados" onChange={(e) => setSt_cuidados(e.target.value)}>
                  <FormControlLabel value="Sim" control={<Radio color="primary"/>} label="SIM" />
                  <FormControlLabel value="Não" control={<Radio color="primary"/>} label="NÂO" />   
                </RadioGroup>
              </FormControl> 

              <TextField  
                margin="normal"
                style={{  width: '100%' }}      
                id="nm_responsavel"
                label="Nome do responsável em cuidar"
                variant="outlined"
                value={nm_responsavel} 
                onChange={(e) => setNm_responsavel(e.target.value)}
              />

              <TextField   
                margin="normal"
                style={{ width: '100%' }}     
                id="ds_observacao"
                label="Observação"
                variant="outlined"
                value={ds_observacao} 
                onChange={(e) => setDs_observacao(e.target.value)}
                multiline='true'
                rows='4'
              />

              <br></br><br></br>

              <Button onClick={handleClose} style={{ float: 'right', marginLeft:15, backgroundColor:"red" }} variant="contained" color="primary">
                Fechar
              </Button>

              <Button onClick={(editing===false)?enviar:editar} style={{ float: 'right' }}  variant="contained" color="primary">
                Enviar
              </Button>

              <br></br><br></br>
              
            </div>

        </Modal>
      </div>

    </div>
)}