import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {Button, CircularProgress, Backdrop, Modal, Grid, Paper} from '@material-ui/core';
import swal from 'sweetalert';
import MaterialTable from 'material-table'
import { LaConFetch } from 'links/LaConFetch';
import {link} from '../../links/Links';
import { _MaterialTableLocalization } from 'links/TableConstant';

const useStyles = makeStyles((theme) => ({
  
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '80%'
  },

}));
   
export default function Participacao({familia_id}) {

  const classes = useStyles();
  
  // Pessoa
  const [pessoa_id, setPessoa_id] = useState("");
  const [oficina_id, setOficina_id] = useState("");
  const [nm_pessoa, setNm_pessoa] = useState("");

  // Participacao
  const [id, setId] = useState("");
  const [cd_servico, setCd_servico] = useState("");
  const [nm_servico, setNm_servico] = useState("");
  const [cd_unidade, setCd_unidade] = useState("");
  const [ds_unidade, setDs_unidade] = useState("");
  const [dt_ingresso, setDt_ingresso] = useState("");
  const [dt_desligamento, setDt_desligamento] = useState("");
  const [ds_observacao, setDs_observacao] = useState("");
  const [nome_oficina, setNomeOficina] = useState("");

  const [nm_servico_outro, setNm_servico_outro] = useState("");
  const [outroServicoOff, setOutroServicoOff] = useState(true);

  const [ds_unidade_outro, setDs_unidade_outro] = useState("");
  const [outroUnidadeOff, setOutroUnidadeOff] = useState(true);

  const [servicos,setServicos] = useState([])

  /* Set Serviço */
  const setServico = (codigo) =>{

    setCd_servico(codigo);

    if (codigo === '1'){  
      setNm_servico_outro(null);
      setOutroServicoOff(true);
      setNm_servico('Serviço de Convivência e Fortalecimento de Vínculos para crianças e/ou adolescentes');
    }
    else if (codigo === '2'){
      setNm_servico_outro(null);
      setOutroServicoOff(true);
      setNm_servico('Serviço de Convivência e Fortalecimento de Vínculos para idosos');
    }      
    else if (codigo === '3'){
      setNm_servico_outro(null);
      setOutroServicoOff(true);
      setNm_servico('Grupo específico desenvolvido pelo PAIF');
    }
    else if (codigo === '4'){
      setNm_servico_outro(null);
      setOutroServicoOff(true);
      setNm_servico('Grupo específico desenvolvido pelo PAEFI');
    }
    else if (codigo === '5'){
      setNm_servico_outro(null);
      setOutroServicoOff(true);
      setNm_servico('Programas ou projetos específicos da Assistência Social que não configurem serviços continuados');
    }
    else if (codigo === '6'){
      setNm_servico_outro(null);
      setOutroServicoOff(true);
      setNm_servico('Programas ou projetos de outras políticas setoriais (Educação, Esporte, Cultura, etc)');
    }
    else if(codigo === '99'){
      setOutroServicoOff(false);
    }
    
  }
  
  const setServicoOutro = (texto) =>{
  
    setNm_servico_outro(texto);
    setNm_servico(texto);
  }

  /* Set Unidade */
  const setUnidade = (codigo) =>{

    setCd_unidade(codigo);

    if (codigo === '1'){  
      setDs_unidade_outro(null);
      setOutroUnidadeOff(true);
      setDs_unidade('Nesta própria Unidade');
    }
    else if (codigo === '2'){
      setDs_unidade_outro(null);
      setOutroUnidadeOff(true);
      setDs_unidade('Em outra Unidade Pública da rede Socioassistencial');
    }      
    else if (codigo === '3'){
      setDs_unidade_outro(null);
      setOutroUnidadeOff(true);
      setDs_unidade('Em unidade/entidade privada da rede socioassistencial');
    }
    else if (codigo === '4'){
      setDs_unidade_outro(null);
      setOutroUnidadeOff(true);
      setDs_unidade('Em unidade da rede de educação');
    }
    else if(codigo === '9'){
      setOutroUnidadeOff(false);
    }
    
  }
  
  const setUnidadeOutro = (texto) =>{
  
    setDs_unidade_outro(texto);
    setDs_unidade(texto);
  }

  const [sending, setSending] = useState(false);

  /* Cadastrar Participação */
  const enviar = () => {

    if(pessoa_id === "")
      swal("Pessoa é obrigatório!","", "error")

    else if(cd_servico === "")
      swal("Código do serviço é obrigatório!","", "error")

    else if(oficina_id === "")
      swal("Serviço é obrigatório!","", "error")

    else{

      const formDados = {
        pessoa_id,
        cd_servico, 
        nm_servico, 
        cd_unidade,
        ds_unidade, 
        dt_ingresso, 
        dt_desligamento,
        ds_observacao,
        oficina_id,
      };

      setSending(true);

        LaConFetch(`/api/cadastrarParticipacao`,
        result => {
          load();
          setSending(false);
          swal("Enviado com sucesso!","", "success")
          setOpen(false)
        },()=>{},formDados)

    }
  };

  /* Carregar Pessoa e Participacao */
  const [rows, setRows] = useState([]);
  const [cursos, setCursos] = useState([]);
  
  const load = () => {
    
    setSending(true);
    LaConFetch(`/api/resultPessoa/${familia_id}`,
      result => {
        if(result.dados !== null){
          setDadosPessoa(result.dados)
        }
    },result => setSending(false))

    LaConFetch(`/api/resultParticipacao/${familia_id}`,
      result => {
        if(result.dados !== null){
          setRows(result.dados)
          setSending(false);
        }
    },result => setSending(false))

    LaConFetch(`/api/oficina/oficina`, result => {
        setCursos(result.items)
    },()=>{})


  };

  
  useEffect(() => {
    
    load();
    
  }, [])

  const [dadosPessoa, setDadosPessoa] = useState([{pessoa_id:"" , nm_pessoa:""}]);

  /* SetDadosParticipacao */
  const setDadosParticipacao = (dados) => {

    /* Dados */
    setId(dados.id);
    setServico(dados.cd_servico);
    if(dados.cd_servico === '99'){
      setNm_servico_outro(dados.nm_servico);
    }
    setUnidade(dados.cd_unidade);
    if(dados.cd_unidade === '9'){
      setDs_unidade_outro(dados.ds_unidade);
    }
    setDt_ingresso(dados.dt_ingresso);
    setDt_desligamento(dados.dt_desligamento);
    setDs_observacao(dados.ds_observacao);
    setNm_pessoa(dados.nm_pessoa);
    setPessoa_id(dados.pessoa_id);
    setNomeOficina(dados.oficina);
  };

   /* Limpa os dados */
   const clear = () => {
 
    /* Dados */
    setId("");
    setCd_servico("");
    setNm_servico("");
    setCd_unidade("");
    setDs_unidade("");
    setDt_ingresso("");
    setDt_desligamento("");
    setDs_observacao("");
    setNm_servico_outro("");
    setDs_unidade_outro("");
    setNm_pessoa("");
    setPessoa_id("");
  };

  const [open, setOpen] = React.useState(false);

  const [editing, setEditing] = React.useState(false);

  const handleOpen = () => {
    clear();
    setEditing(false)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const modalEdit = (dados) => {
    setDadosParticipacao(dados);
    setEditing(true)
    setOpen(true);
  };

  /* Editar Participação */
  const editar = () => {

    const formDados = {
      cd_servico, 
      nm_servico, 
      cd_unidade,
      ds_unidade, 
      dt_ingresso, 
      dt_desligamento,
      ds_observacao};

    setSending(true);
      LaConFetch(`/api/editarParticipacao/${id}`, result => {
        load();
        setSending(false);
        swal("Editado com sucesso!","", "success")
        setOpen(false)
      },()=>{},formDados)

  };

  /* Remover Participação */
  const remover = (dados) => {

    const id = dados.pessoa_id;
    let form = {}
    setSending(true);

    LaConFetch(`/api/removerParticipacao/${id}`,(r)=>{
      setSending(false)
    },()=>{
      setSending(false)
    },form)

  };

  return (
    
    <div className={classes.root}>

      <Backdrop style={{zIndex:99}} open={sending}>
          <CircularProgress color="inherit" />
      </Backdrop>

      <Button onClick={handleOpen} style={{ float: 'right' }}  variant="contained" color="primary">
        Adicionar Participação
      </Button>

      <br></br><br></br>
      <br></br><br></br>

      <div className="justify-content-center ml-5">

      <MaterialTable 
        columns={[
          { title: 'Nome', field: 'nm_pessoa', headerStyle: {fontWeight: 'bold'} },
          { title: 'Cód. Serviço', field: 'cd_servico', headerStyle: {fontWeight: 'bold'} },
          { title: 'Nome Serviço', field: 'nm_servico', headerStyle: {fontWeight: 'bold'} }
        ]}
        data={rows}
        actions={[
            {
              icon: 'edit',
              tooltip: 'Editar',
              onClick: (e,data) => modalEdit(data)
            },
            {
              icon: 'delete',
              tooltip: 'Remover',
              onClick: (e,data) => swal("Tem certeza que deseja remover o registro?", {
                buttons: {
                  remover: "Remover",
                  cancel: "Sair"
                },
              })
              .then((value) => {
                switch (value) {
                  case "remover":
                    remover(data);
                    swal("Removido com sucesso!","", "success");
                    load();
                    break;
                }
              })
            }
        ]}
        options={{
            actionsColumnIndex: -1
        }}
        localization={_MaterialTableLocalization}
        title="Participação" 
      />
      </div>

      <div>
        <Modal
          className={classes.modal} open={open}
          onClose={handleClose} closeAfterTransition BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500, }}
        >
            <div className={classes.paper}>
              <InputLabel style={{ marginTop: 20, fontSize: 20  }}>Participação</InputLabel>

            <Grid container>

              <Grid item xs={12}>
              {!editing ? <TextField
                variant="outlined" fullWidth margin='normal'
                value={pessoa_id}
                label="Nome da pessoa*"  
                onChange={(e) => setPessoa_id(e.target.value)}
                select>
                  {dadosPessoa.map(item =>
                    <MenuItem value={item.id}>{item.nm_pessoa}</MenuItem>
                  )}
              </TextField>:<TextField
                disabled={true} margin="normal" variant="outlined"
                value={nm_pessoa} fullWidth
                label="Nome da pessoa"  
                />}
              </Grid>
              
              <Grid item lg={8}>
              <TextField
                variant="outlined" fullWidth margin='normal'
                value={cd_servico}
                label="Código do serviço*"  
                onChange={(e) => setServico(e.target.value)}select
              >
                <MenuItem value="1">1 - Serviço de Convivência e Fortalecimento de Vínculos para crianças e/ou adolescentes</MenuItem>
                <MenuItem value="2">2 - Serviço de Convivência e Fortalecimento de Vínculos para idosos</MenuItem>
                <MenuItem value="3">3 - Grupo específico desenvolvido pelo PAIF</MenuItem>
                <MenuItem value="4">4 - Grupo específico desenvolvido pelo PAEFI</MenuItem>
                <MenuItem value="5">5 - Programas ou projetos específicos da Assistência Social que não configurem serviços continuados</MenuItem>
                <MenuItem value="6">6 - Programas ou projetos de outras políticas setoriais (Educação, Esporte, Cultura, etc)</MenuItem>
                <MenuItem value="99">99 - Outro</MenuItem>        
              </TextField>
              </Grid>

              <Grid item lg={4}>
                <TextField   
                  value={nm_servico_outro}
                  disabled={outroServicoOff}
                  margin="normal" fullWidth variant="outlined"
                  label="Caso outro, qual?"
                  onChange={(e) => setServicoOutro(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                {!editing ? <TextField
                  variant="outlined" fullWidth margin="normal"
                  value={oficina_id} label="Curso/Oficina Vinculada"  
                  onChange={(e) =>setOficina_id(e.target.value)} select
                >
                    {cursos.map(item =>
                      <MenuItem value={item.id}>{item.titulo}</MenuItem>
                    )}
                </TextField>:<TextField
                  disabled={true} margin="normal" variant="outlined"
                  value={nome_oficina} fullWidth
                  label="Curso/Oficina Vinculada"  
                />}
              </Grid>

              <Grid item lg={8}>
                <TextField
                  variant="outlined" fullWidth margin='normal'
                  value={cd_unidade} label="Código da unidade"
                  onChange={(e) => setUnidade(e.target.value)} select
                  >
                  <MenuItem value="1">1 - Nesta própria Unidade</MenuItem>
                  <MenuItem value="2">2 - Em outra Unidade Pública da rede Socioassistencial</MenuItem>
                  <MenuItem value="3">3 - Em unidade/entidade privada da rede socioassistencial</MenuItem>
                  <MenuItem value="4">4 - Em unidade da rede de educação</MenuItem>
                  <MenuItem value="9">9 - Outra unidade vinculada a outras políticas</MenuItem>        
                </TextField>
              </Grid>

              <Grid item lg={4}>
                <TextField
                  value={ds_unidade_outro}   
                  disabled={outroUnidadeOff}
                  margin="normal" fullWidth
                  label="Caso outra, qual?" variant="outlined"
                  onChange={(e) => setUnidadeOutro(e.target.value)}
                />
              </Grid>
              <Grid item lg={3}>
                <TextField
                  variant="outlined" margin="normal" fullWidth
                  label="Data de Ingresso"
                  type="date"
                  value={dt_ingresso}
                  onChange={(e) => setDt_ingresso(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item lg={3}>
                <TextField
                  variant="outlined" margin="normal" fullWidth  
                  label="Data de Desligamento"
                  type="date" value={dt_desligamento}
                  onChange={(e) => setDt_desligamento(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item lg={12}>
                <TextField   
                  margin="normal" fullWidth variant="outlined"
                  label="Observação"
                  value={ds_observacao} 
                  onChange={(e) => setDs_observacao(e.target.value)}
                  multiline rows={4}
                />
              </Grid>
            </Grid>
              
              <br></br><br></br>
              <Button onClick={handleClose} style={{ float: 'right', marginLeft:15, backgroundColor:"red" }} variant="contained" color="primary">
                Fechar
              </Button>
              <Button onClick={(editing===false)?enviar:editar} style={{ float: 'right' }}  variant="contained" color="primary">
                Enviar
              </Button>
              <br></br><br></br>
          </div>
        </Modal>
      </div>

    </div>
)}