import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {Button, CircularProgress, Backdrop, Modal, CardHeader} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert';
import MaterialTable from 'material-table'
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { LaConFetch } from 'links/LaConFetch';
import moment from 'moment';
import {link} from '../../links/Links';
import { _MaterialTableLocalization } from 'links/TableConstant';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="R$ "
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '80%'
  },

}));
   
export default function SolicitaBeneficio({familia_id}) {

  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [sending, setSending] = useState(false);
  const [dadosPessoa, setDadosPessoa] = useState([{pessoa_id:"" , nm_pessoa:""}]);

  /* Cadastrar CondTrabalho*/
  const enviar = () => {
    let url = (registro.id) ? `edit/${registro.id}` : `create`
    let hold = {...registro}
    hold.produtos = produtos
    LaConFetch(`/api/almoxarifado/beneficio/${url}`,
    result => {
      setSending(false);
      swal("Enviado com sucesso!","", "success")
      loadBeneficios()
      setOpen(false)
    },()=>{},hold)
  };

  /* Carregar Pessoa e CondTrabalho*/
  const [beneficios, setBeneficios] = useState([]);
  const [tipos, setTipos] = useState([]);

  //{protocolo:"#SB010320",acao:"Solciitação Individual",data:"17/07/2021 12:00",tipo:"Único",status:"Pendente"},
  //{protocolo:"#SB012320",acao:"Solciitação Individual",data:"15/07/2021 13:13",tipo:"Mensal",status:"Pendente"},

  const [produtosAll, setProdutosAll] = useState([]);

  // {produto_id:1,produto:"Cesta Básica"},
  //   {produto_id:2,produto:"Kit Higiene"},

  const loadBeneficios = () => {
    setSending(true)
    LaConFetch(`/api/almoxarifado/beneficio/familia/${familia_id}`,
      result => {
        if(result.items !== null){
          setBeneficios(result.items)
        }
        setSending(false)
    },result => setSending(false))
  }

  const loadAux = () => {
    setSending(true);
    LaConFetch(`/api/resultPessoa/${familia_id}`,
      result => {
        if(result.dados !== null){
          setDadosPessoa(result.dados)
        }
        setSending(false)
    },result => setSending(false))

    LaConFetch(`/api/almoxarifado/produto`,
      result => {
        if(result.items !== null){
          setProdutosAll(result.items)
        }
        //setSending(false)
    },result => setSending(false))

    LaConFetch(`/api/almoxarifado/tipo-beneficio`,
      result => {
        if(result.items !== null){
          setTipos(result.items)
        }
        //setSending(false)
    },result => setSending(false))
  }

  useEffect(() => {
    loadBeneficios();
    loadAux();
  }, [])

  const [produtos, setProdutos] = useState([]);

  const [adicionar,setAdicionar] = useState({
    produto: '',
    qtd:''
  });

  const [registro,setRegistro] = useState({
    pessoa_id:'',
    anexo: '',
    tipo_id:'',
    obs:'',
    status_id:1
  });

  const handleAdicionar=(field,value)=>{
    let hold = {...adicionar}
    hold[field] = value
    setAdicionar(hold)
  }

  const handleRegistro=(field,value)=>{
    let hold = {...registro}
    hold[field] = value
    setRegistro(hold)
  }

  const addProdutos = () => {
    let hold = [...produtos]
    hold.push({
      produto_id: adicionar.produto.id,
      produto: adicionar.produto.nome,
      qtd: adicionar.qtd,
    })
    setProdutos(hold)
    setAdicionar({
      produto: '',
      qtd:''
    })
  }

  const remProduto = (produto_id) => {
    let hold = [...produtos];
    let cnt = 0;
    hold.forEach(function(item){
      if (item.produto_id === produto_id) {
        hold.splice(cnt,1);
      }
      cnt++
    })
    setProdutos(hold)
  }

  const [open, setOpen] = React.useState(false);

  const [openEdit, setOpenEdit] = React.useState(false);

  const handleOpen = () => {
    setProdutos([])
    setAdicionar({
      produto: '',
      qtd:''
    })
    setRegistro({
      pessoa_id:'',
      anexo: '',
      tipo_id:'',
      obs:'',
      status_id:1
    })
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const modalEdit = (dados) => {
    //setDadosCondTrabalho(dados);
    setOpenEdit(true);
  };

  return (
    
    <div className={classes.root}>

      <Backdrop style={{zIndex:99}} open={sending}>
          <CircularProgress color="inherit" />
      </Backdrop>

      <div className="justify-content-center ml-5">

        <div className="bg-primary" style={{backgroundColor:'rgb(37, 85, 39)',padding:'10px'}}>
          <div className="clearfix">
          <h4 style={{float:'left',margin:'3px 0'}}>Solicitação de Benefícios</h4>
          <Button onClick={handleOpen} style={{ float: 'right' }}  variant="contained" color="primary">
            Nova Solicitação
          </Button>
          </div>
        </div>
          <MaterialTable 

          columns={[
            { title: 'Protocolo', field: 'protocolo', headerStyle: {fontWeight: 'bold'} },
            { title: 'Tipo', field: 'tipo.nome', headerStyle: {fontWeight: 'bold'} },
            { title: 'Data do Processo', field: 'created_at', render: rowData => moment(rowData.created_at,'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm'), headerStyle: {fontWeight: 'bold'} },
            { title: 'Situação', field: 'status.nome', headerStyle: {fontWeight: 'bold'} }
          ]}

          data={beneficios}

          actions={[
              {
                icon: 'edit',
                tooltip: 'Editar',
                onClick: (e,data) => {
                  setRegistro({
                    tipo_id: data.tipo_id,
                    obs: data.obs,
                    id: data.id,
                    pessoa_id: data.pessoa_id,
                  })
                  setOpen(true)
                }
              },
          ]}

          options={{
              actionsColumnIndex: -1,
              rowStyle: rowData => ({
                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
              })
          }}

          localization={_MaterialTableLocalization}

          title="Lista de Solicitações" />
              
      </div>

      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>

            <div className={classes.paper} style={{maxHeight: '90vh',overflowY:'auto'}}>

              <InputLabel style={{ marginTop: 20, fontSize: 20  }}>Solicitação de Benefício</InputLabel>        

              <Grid container spacing={3}>

                <Grid item lg={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: 15}} 
                    id="pessoa_id" 
                    value={registro.pessoa_id}
                    label="Nome da pessoa*"  
                    onChange={(e) => handleRegistro('pessoa_id',e.target.value)}
                    select>
                      {dadosPessoa.map(item =>
                        <MenuItem value={item.id}>{item.nm_pessoa}</MenuItem>
                      )}
                    </TextField>
                </Grid>
                
                <Grid item lg={4}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: 15 }}
                    id="tipo_id" 
                    value={registro.tipo_id}
                    label="Tipo*"  
                    onChange={(e) => handleRegistro('tipo_id',e.target.value)}
                    select>
                    {tipos.map(item =>
                      <MenuItem value={item.id}>{item.nome}</MenuItem>
                    )}
                  </TextField>
                </Grid>
                <Grid item lg={8}>
                  <p>Anexo</p>
                </Grid>

                <Grid item lg={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: 15 }}
                    id="produto_id" 
                    value={adicionar.produto}
                    label="Produto*"  
                    onChange={(e) => handleAdicionar('produto',e.target.value)}
                    select>
                    {produtosAll.map(item =>
                      <MenuItem value={item}>{item.nome}</MenuItem>
                    )}          
                  </TextField>
                </Grid>
                <Grid item lg={3}>
                  <TextField   
                    margin="normal"
                    fullWidth
                    id="qtd"
                    label="Quantidade"
                    variant="outlined"
                    value={adicionar.qtd} 
                    onChange={(e) => handleAdicionar('qtd',e.target.value)}
                  />
                </Grid>
                <Grid item lg={3}>
                  <Button variant="contained" size="large" color="primary" style={{padding:'17px',marginTop:'15px'}} onClick={()=>addProdutos()}>
                    Adicionar
                  </Button>
                </Grid>

                <Grid item lg={12}>
                <MaterialTable 

                    columns={[
                      { title: 'Produto', field: 'produto', headerStyle: {fontWeight: 'bold'} },
                      { title: 'Quantidade', field: 'qtd', headerStyle: {fontWeight: 'bold'} },
                    ]}

                    data={produtos}

                    actions={[
                        {
                          icon: 'remove',
                          tooltip: 'Remover',
                          onClick: (e,data) => {
                            remProduto(data.produto_id)
                          }
                        },
                    ]}

                    components={{Container:'div'}}

                    options={{
                        actionsColumnIndex: -1,
                        rowStyle: rowData => ({
                          backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                        }),
                        search: false,
                        showTitle: false,
                        toolbar: false
                    }}

                    localization={_MaterialTableLocalization}
                    />
                </Grid>

                <Grid item lg={12}>
                    <TextField   
                    margin="normal"
                    fullWidth    
                    id="obs"
                    label="Observação"
                    variant="outlined"
                    value={registro.obs} 
                    onChange={(e) => handleRegistro('obs',e.target.value)}
                    multiline='true'
                    rows='4'
                    />
                </Grid>

              </Grid>
      
              <br></br><br></br>

              <Button onClick={handleClose} style={{ float: 'right', marginLeft:15, backgroundColor:"red" }} variant="contained" color="primary">
                Fechar
              </Button>

              <Button onClick={enviar} style={{ float: 'right' }}  variant="contained" color="primary">
                Enviar
              </Button>

              <br></br><br></br>

            </div>

        </Modal>
      </div>

    </div>
)}