import { Backdrop, CircularProgress, Grid, IconButton, Paper } from '@material-ui/core'
import { LaConFetch } from 'links/LaConFetch'
import { _MaterialTableLocalization } from 'links/TableConstant'
import MaterialTable from 'material-table'
import React, { useState, useEffect } from 'react'
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import swal from 'sweetalert';
import CloseIcon from '@material-ui/icons/Close';

export default function ModalMovimento({id,onClose,...props}){
    const [items,setItems] = useState([])
    const [loading,setLoading] = useState(false)
    const [sending,setSending] = useState(false)

    useEffect(()=>{
        if(id>0){
            setLoading(true)
            LaConFetch(`/api/almoxarifado/movimento/show/${id}`,(r)=>{
                if(r.success){
                    setItems(r.item.movimentos)
                }
                setLoading(false)
            },()=>{})
        }
    },[id])

    const onRemove=(id)=>{
        setSending(true)
        let form ={}
        LaConFetch(`/api/almoxarifado/movimento/force/${id}`,(r)=>{
            if(r.success){
                swal("Estoque atualizado com sucesso!",r.message, "success");
            }else{
                swal("Oops",r.message,"error");
            }
            setSending(false)
        },()=>{
            setSending(false)
        },form)
    }

    const onRemoveEnuogh=(row)=>{
        if(row.rejeitado){
            swal(`Tem certeza que deseja remover apenas o estoque suficiente de ${row.estoque.produto.nome}?`, {
                buttons: {
                remover: "Remover",
                cancel: "Sair"
                },
            })
            .then((value) => {
                switch (value) {
                case "remover":
                    onRemove(row.id)
                    break;
                }
            })
        }
    }
    return <div>
        <Backdrop style={{zIndex:99}} open={sending}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Paper style={{padding:10}}>
            <Grid container spacing={2} justifyContent='space-between'>
                <Grid item xs={11}>
                    <h5>Produtos Movimentados:</h5>
                </Grid>
                <Grid item xs={1}>
                    <div style={{width:'100%',textAlign:'right'}}>
                        <IconButton onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </Grid>
            </Grid>
            <MaterialTable
                data={items} title='Produtos'
                components={{Container:'div'}}
                isLoading={loading}
                columns={[
                    {title:'#',field:'id'},
                    {title:'Produto',field:'estoque.produto.nome'},
                    {title:'Quantidade',field:'quantidade'},
                    {title:'Status',field:'movido',render:(data)=>{
                        if(data.movido){
                            if(data.missing>0){
                                return <span className='badge' style={{backgroundColor:'limegreen'}}>Conluído, faltam {data.missing}</span>
                            }else{
                                return <span className='badge' style={{backgroundColor:'limegreen'}}>Conluído</span>
                            }
                        }else if(data.rejeitado){
                            return <span className='badge' style={{backgroundColor:'orange'}}>Estoque Insuficiente</span>
                        }
                    }},
                ]}
                actions={[
                    (rowData)=>({
                        disabled: !rowData.rejeitado,tooltip:'Retirar apenas em estoque',icon:UnarchiveIcon,onClick:(e,row)=>onRemoveEnuogh(row)
                    })
                ]}
                options={{
                    actionsColumnIndex: -1,
                }}
                localization={_MaterialTableLocalization}
            />
        </Paper>
    </div>
}